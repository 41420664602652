<template>

    <body
        id="body-pd"
        :class="'v-background ' + showMenuInit"
    >
        <header
            id="header"
            :class="onGetClass()"
            class="header body-pd"
        >
            <div class="header_toggle show">
                <i
                    class='fas fa-bars'
                    id="header-toggle"
                    data-automation="button-menu"
                ></i>
            </div>
            <VFButtonAuth />
        </header>

        <VFMenu :class="onGetClass()" />

        <div class="height-auto mb-5">
            <router-view></router-view>
        </div>
    </body>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { VFMenu, VFButtonAuth } from '@/components';

export default {
    name: 'App',
    components: {
        VFMenu,
        VFButtonAuth
    },
    setup () {
        const router = useRouter();

        const showMenuInit = computed(() => {
            return (router.currentRoute.value.name !== 'Login') ? 'body-pd' : '';
        });

        const onGetClass = () => {
            return (router.currentRoute.value.name === 'Login') ? 'd-none' : '';
        };

        const showNavbar = (toggleId, navId, bodyId, headerId) => {
            const toggle = document.getElementById(toggleId),
                nav = document.getElementById(navId),
                body = document.getElementById(bodyId),
                header = document.getElementById(headerId);

            if (toggle && nav && body && header) {
                toggle.addEventListener('click', () => {
                    nav.classList.toggle('show-menu');
                    toggle.classList.toggle('bx-x');
                    body.classList.toggle('body-pd');
                    header.classList.toggle('body-pd');
                    const screenWidth = window.innerWidth;

                    document.getElementById("image_logo").classList.add("d-none");
                    document.getElementById("image_logo_reduced").classList.remove("d-none");
                    if ((screenWidth >= 768) && (nav.classList.contains("show-menu"))) {
                        document.getElementById("image_logo").classList.remove("d-none");
                        document.getElementById("image_logo_reduced").classList.add("d-none");
                    }
                })
            }
        };

        onMounted(() => {
            showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header');
        });

        return {
            onGetClass,
            showMenuInit
        }
    }
}
</script>
