import { reindex_object } from "@/helpers";

export const setFragmentCodes = ( state, fragmentCodes ) => {
    state.fragmentCodes = {
        ...state.fragmentCodes,
        ...fragmentCodes
    };
}

export const deleteFragmentCodeByElementIndex = ( state, { elementIndex, removePosition }) => {
    if ( state.fragmentCodes[ elementIndex ] ) {
        delete state.fragmentCodes[ elementIndex ];
    }

    if ( removePosition ) {
        state.fragmentCodes = reindex_object( state.fragmentCodes, elementIndex );
    }
}

