import { sngApi } from "@/api";

/**
 *  Get all fragment codes
 */
export const getFragmentCodesForSelect = async ({ commit }, { t, elementIndex, supportedCharactersId = {} }) => {
    try {
        const filterCharacter = supportedCharactersId.value
            ? `?supported_character_id=${ supportedCharactersId.value }`
            : '';

        const { data } = await sngApi.get(`/api/fragment_codes${ filterCharacter }`);

        const fragmentCodes = data.reduce((acc, { id, length, supported_character }) => {
            const supportedCharacterId = supported_character.id;
            if (!acc[ elementIndex ]) {
                acc[ elementIndex ] = {};
            }

            if ( !acc[ elementIndex ][ supportedCharacterId ]) {
                acc[ elementIndex ][ supportedCharacterId ] = [];
            }

            acc[ elementIndex ][ supportedCharacterId ].push({
                value: id,
                name: length
            });

            return acc;
        }, {});

        commit('setFragmentCodes', fragmentCodes );

        return {
            ok: true
        }
    } catch (error) {
        let message = t('fragment_codes.api.get_all_error');
        if (error.response?.data.message) {
            message = error.response.data.message;
        }

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Create fragment codes
 */
export const createFragmentCode = async ( _, { fragmentCodeLength, supportedCharacterId }) => {
    return await sngApi.post('/api/fragment_codes', {
        'fragment_code': {
            'supported_character_id': supportedCharacterId,
            'length': fragmentCodeLength
        }
    });
}
