import { sngApi } from "@/api";
import { getConnectionsMessageError } from "@/helpers";

/**
 *  Get all supported characters
 */
export const getSupportedCharacters = async ({ commit }, { t, store, characterToSearch }) => {
    commit('setLoading', true);

    const resp = await store.dispatch('supportedCharacters/getSupportedCharacters', { t, characterToSearch });

    commit('setLoading', false);

    return resp;
}

/**
 *  Get all fragment codes
 */
export const getFragmentCodes = async ({ commit }, { t, store, elementIndex, supportedCharactersId = {} }) => {
    commit('setLoading', true);

    const resp = await store.dispatch(
        'fragmentCodes/getFragmentCodesForSelect',
        { t, elementIndex, supportedCharactersId }
    );

    commit('setLoading', false);

    return resp;
}

/**
 *  Get all generator rules
 */
export const getGeneratorRules = async ({ commit }, { t, generatorRuleToSearch = '' }) => {
    try {
        commit('setLoading', true);

        const filterCharacter = generatorRuleToSearch
            ? `&name[lk]=%25${ generatorRuleToSearch }%25`
            : '';
        const { data } = await sngApi.get(
            `/api/series_generator/rules?relations=generation_function${ filterCharacter }`
        );

        const generatorRules = data.map(item => ({
            value: item.id,
            name: `${ item.name.toUpperCase() } - ${ item.generation_function.name }`,
            describe: {
                cluster_size: item.cluster_size,
                generation_size: item.generation_size
        }
        }));

        commit( 'setGeneratorRules', generatorRules );

        commit('setLoading', false);

        return {
            ok: true
        }
    } catch ( error ) {
        commit('setLoading', false);

        const message = getConnectionsMessageError(
            error,
            t('generator_rules.api.get_all_error')
        )

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Create a generator rule
 */
export const createGenerationRule = async ({ commit }, { t, data: dataForm }) => {
    try {
        commit( 'setLoading', true );

        const fragmentCodes = dataForm.map(( item, index ) => ({
            fragment_code_id: item.fragment_code_id,
            fragment_position: index + 1
        }));

        /**
         * TODO:
         ** el generation_function_code se debe reemplazar por el valor elegido (no hardcodeado)
         */
        const params = {
            series_generator_rule: {
                name: dataForm.generatorRuleName,
                generation_function_code: 'SNP',
                cluster_size: dataForm.totalClusterSize,
                fragment_codes: fragmentCodes
            }
        };

        await sngApi.post('/api/series_generator_rules', params );

        commit( 'setLoading', false );

        return {
            ok: true,
            message: t('generator_rules.api.create_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        let message = t('generator_rules.api.create_error');
        if ( error?.response?.data?.message ) {
            const backendMessage = error.response.data.message;

            message += backendMessage.includes('already been taken')
                ? `\n - ${ t('generator_rules.api.generator_rule_exist') }`
                : `\n - ${ error.response.data.message }`;
        }

        return {
            ok: false,
            message
        }
    }
}
