/**
 * Clears the state store of specific modules based on the route `from` and `to` paths.
 *
 * @param {object} store - The Vuex store object.
 * @param {object} from - The route object representing the previous route.
 * @param {object} to - The route object representing the current route.
 */
export const clearModuleStore = ( store, from, to ) => {
    const modulePaths = {
        commodityIdentifications: '/commodity-identifications',
        seriesComposition: '/series-composition',
        generatorRules: '/generator-rules'
    };

    if (
        from.path.startsWith( modulePaths.commodityIdentifications )
        && !to.path.startsWith( modulePaths.commodityIdentifications )
    ) {
        store.commit( 'commodityIdentifications/clearStateStore' );
    }

    if (
        from.path.startsWith( modulePaths.seriesComposition )
        && !to.path.startsWith( modulePaths.seriesComposition )
    ) {
        store.commit( 'seriesComposition/clearStateStore' );
    }

    if (
        from.path.startsWith( modulePaths.generatorRules )
        && !to.path.startsWith( modulePaths.generatorRules )
    ) {
        store.commit( 'generatorRules/clearStateStore' );
    }
}

/**
 * Determines whether the given variable is empty.
 *
 * @param {*} variable - The variable to check if it is empty.
 * @returns {boolean} - True if the variable is empty, false otherwise.
 */
export const is_empty_var = ( variable ) => variable === '';

/**
 * Determines if the variable length exceeds the given length.
 *
 * @param {number} variableLength - The length of the variable to be checked.
 * @param {number} length - The threshold length to compare against.
 * @returns {boolean} - True if variable length exceeds the given length, false otherwise.
 */
export const max_length_var = ( variableLength, length ) => variableLength > length;

/**
 * Determines if the length of a variable is greater than or equal to the given length.
 *
 * @param {number} variableLength - The length of the variable to compare.
 * @param {number} length - The length to compare against.
 * @returns {boolean} True if the variable length is greater than or equal to the given length, otherwise false.
 */
export const max_equals_length_var = ( variableLength, length ) => variableLength >= length;

/**
 * Determines if the length of a variable is less than the specified length.
 *
 * @param {number} variableLength - The length of the variable.
 * @param {number} length - The desired length.
 * @returns {boolean} True if the length of the variable is less than the specified length, false otherwise.
 */
export const min_length_var = ( variableLength, length ) => variableLength < length;

/**
 * Determines if the length of a given variable is less than or equal to a specified length.
 *
 * @param {number} variableLength - The length of the variable.
 * @param {number} length - The specified length to compare against.
 * @returns {boolean} - True if the length of the variable is less than or equal to the specified length, otherwise false.
 */
export const min_equals_length_var = ( variableLength, length ) => variableLength <= length;

/**
 * Determines whether the given variable value is greater than the specified value.
 *
 * @param {number} variableValue - The value of the variable to be compared.
 * @param {number} value - The value to compare against.
 * @returns {boolean} - True if variableValue is greater than value, false otherwise.
 */
export const max_value_var = ( variableValue, value ) => variableValue > value;

/**
 * Checks if the given variable value is equal to or greater than the specified value.
 *
 * @param {number} variableValue - The value of the variable to be compared.
 * @param {number} value - The value to compare against the variable value.
 * @returns {boolean} - `true` if the variable value is equal to or greater than the specified value,
 *                     `false` otherwise.
 */
export const max_equals_value_var = ( variableValue, value ) => variableValue >= value;

/**
 * Checks if the value of a given variable is less than a specified value.
 *
 * @param {number|string} variableValue - The value of the variable to compare.
 * @param {number|string} value - The value to compare against.
 * @returns {boolean} - Returns true if the variable value is less than the specified value, otherwise returns false.
 */
export const min_value_var = ( variableValue, value ) => variableValue < value;

/**
 * Checks if the given variable value is less than or equal to the specified value.
 *
 * @param {number|string} variableValue - The value of the variable to compare.
 * @param {number|string} value - The value to compare the variable value against.
 * @returns {boolean} - true if the variable value is less than or equal to the specified value, false otherwise.
 */
export const min_equals_value_var = ( variableValue, value ) => variableValue <= value;

/**
 * Checks if there are any errors in the given object.
 *
 * @param {Object} errors - The object to check for errors.
 * @returns {boolean} - A boolean value indicating if there are any errors.
 */
export const have_errors = ( errors ) => Object.keys( errors ).length === 0;

/**
 * Reindexes an object after a specific element is deleted by decrementing keys greater than the deleted index.
 *
 * @param {Object} object - The original object to be reindexed
 * @param {number} elementIndexDeleted - The index of the element that was deleted
 * @returns {Object} - The reindexed object with adjusted keys
 */
export const reindex_object = ( object, elementIndexDeleted ) => {
    let objectReindexed = {};
    Object.keys( object ).forEach(( key ) => {
        const keyParsed = parseInt( key );
        const newIndex = ( keyParsed > elementIndexDeleted )
            ? keyParsed - 1
            : keyParsed;
        objectReindexed[ newIndex ] = object[ key ];
    });
    return objectReindexed;
}

/**
 * Function to truncate a given text to a specified maximum length.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length allowed for the text.
 * @returns {string} - The truncated text if it exceeds the maxLength, otherwise the original text.
 */
export const truncateText = ( text, maxLength ) => {
    return text.length > maxLength
        ? `${ text.slice( 0, maxLength )}...`
        : text;
};

/**
 * Check if a given value is alphanumeric.
 *
 * @param {string} value - The value to check.
 * @returns {boolean} - True if value is alphanumeric, false otherwise.
 */
export const is_alphanumeric_var = ( value ) => {
    return /^[a-zA-Z0-9]*$/.test( value );
};
