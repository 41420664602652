import { ref, computed } from 'vue';
import { useVFTooltip } from '@/composables/index';

export default function useVFButton(props, emit) {
    const { onHideTooltips } = useVFTooltip();

    const size = ref(props.size);
    const vfClass = ref(props.vfClass);
    const icon = ref(props.icon);
    const iconDir = ref(props.iconDir);
    const iconColor = ref(props.iconColor);

    const onGetClasses = computed(() => {
        let buttonClass = vfClass.value ?  `vf-${vfClass.value}` : '';
        let sizeValue = size.value ? `btn-${size.value}` : '';
        return `${buttonClass} ${sizeValue}`;
    });

    const onGetIcon = computed(() => (icon.value && iconDir.value) ? `${icon.value} float-${iconDir.value} ${iconColor.value}` : '' );

    const onHandleClick = () => {
        onHideTooltips();
        emit('action-click');
    };

    return {
        icon,
        iconDir,
        size,
        vfClass,
        onGetClasses,
        onGetIcon,
        onHandleClick
    }
}
