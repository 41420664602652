import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

export default function useVFButtonAuth () {
  const router = useRouter();
  const route = useRoute();
  let urlVF2 = ref('');
  let fromToVF2 = ref('');
  let enableButtonBack = ref(true);

  watchEffect(() => {
    const redirect_data = route.query.redirect_data;
    if (redirect_data) {
      const params = new URLSearchParams(atob(redirect_data));
      fromToVF2.value = params.get("client");
      localStorage.setItem("FromToVF2", fromToVF2.value);
      localStorage.setItem("token", params.get("token"));
      urlVF2.value = params.get("url_referrer") || document.referrer || '';
      localStorage.setItem("urlVF2", urlVF2.value);

      const restQuery = { ...route.query };
      delete restQuery.redirect_data;
      router.replace({ query: restQuery });
    } else {
      fromToVF2.value = localStorage.getItem("FromToVF2") || '';
      urlVF2.value = localStorage.getItem("urlVF2") || '';
    }

    enableButtonBack.value = !!urlVF2.value;
  });

  return {
    urlVF2,
    fromToVF2,
    enableButtonBack
  }
}
