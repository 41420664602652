import {
    onErrorNotification,
    onSuccessNotification
} from '@/helpers/viewNotifications';

/**
 * Handler function for responses
 *
 * @param {Object} toast - Toast service for notifications
 * @param {Object} router - Vue Router instance for navigating routes
 * @param {string} routeToName - Name of the route to navigate to upon success
 * @param {string} errorMessage - Default error message to display on failure
 */
export const handleResponse = ( toast, router, routeToName, errorMessage ) => {
    return ( response ) => {
        if ( response.ok ) {
            onSuccessNotification( toast, response.message );
            router.push({ name: routeToName });
        } else {
            onErrorNotification( toast, response.message ?? errorMessage );
        }
    };
}

/**
 * Handler function for error response
 *
 * @param {Function} toast - The function to display a toast notification
 * @param {string} errorMessage - The default error message to display if error message is not available
 */
export const handleError = ( toast, errorMessage ) => {
    return ( error ) => {
        onErrorNotification( toast, error.message ?? errorMessage );
    };
}
