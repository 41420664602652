import { sngApi } from "@/api";

/**
 *  Get all supported characters
 */
export const getSupportedCharacters = async ({ commit }, { t, characterToSearch = '' }) => {
    try {
        const filterCharacter = characterToSearch
            ? `?characters[lk]=%25${ characterToSearch }%25`
            : '';
        const { data } = await sngApi.get(`/api/supported_characters${ filterCharacter }`);

        const supportedCharactersList = data.map(item => ({
            value: item.id,
            name: item.characters
        }));

        commit( 'setSupportedCharacters', supportedCharactersList );

        return {
            ok: true
        }
    } catch (error) {
        let message = t('supported_characters.api.get_all_error');
        if (error.response?.data.message) {
            message = error.response.data.message;
        }

        return {
            ok: false,
            message
        }
    }
}

/**
 * Create supported characters
 */
export const createSupportedCharacter = async ( _, supported_characters ) => {
    return await sngApi.post('/api/supported_characters', {
        'supported_character': {
            'characters': supported_characters
        }
    });
}
