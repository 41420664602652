<template>
    <label v-if="titleElement" :class="titleClass" :for="id">
        {{ titleElement }}
        <span class="text-primary" v-if="isRequired">*</span>
    </label>

    <div :class="`${ onHasIcon }  ${ widthClass }`">
        <span v-if="iconDir === 'left'" class="input-group-text" :class="classIcon">
            <i :class="onGetIcon"></i>
        </span>

        <input
            :type="type"
            class="form-control vf-input"
            :class="totalyClass"
            :name="name"
            :id="id"
            :placeholder="placeholder"
            :isRequired="isRequired"
            :value="modelValue"
            :disabled="disabled"
            :ref="id"
            :valid="valid"
            @input="onUpdateModelValue"
            :data-automation="`input-${ id }`"
        />

        <span v-if="iconDir === 'right'" class="input-group-text" :class="classIcon">
            <i :class="onGetIcon"></i>
        </span>
    </div>

    <span v-if="textBelow" :class="classBelow">{{ textBelow }}</span>
</template>

<script>
    import { onMounted } from 'vue';
    import { formProps } from '@/mixins';
    import { useVFInput } from '@/composables';

    export default {
        name: 'VFInput',
        mixins: [
            formProps
        ],
        setup(props, { emit }) {
            const {
                totalyClass,
                valid,
                onGetClasses,
                onGetIcon,
                onHasIcon,
                onUpdateModelValue
            } = useVFInput(props, emit);

            onMounted(()=>{
                totalyClass.value = onGetClasses(valid.value);
            });

            return {
                totalyClass,
                valid,
                onGetIcon,
                onHasIcon,
                onUpdateModelValue
            };
        }
    };
</script>
