<template>
    <div class="accordion">
        <div class="accordion-button collapsed mb-2 px-2 py-3 border rounded shadow-sm"
             type="button"
             data-bs-toggle="collapse"
             data-bs-target="#collapse-accordion"
             aria-expanded="false"
             aria-controls="#collapse-accordion"
             data-automation="accordion-show"
        >
            <h6 class="title">
                {{ title }}
            </h6>
        </div>

        <div class="accordion-collapse collapse"
             id="collapse-accordion"
             aria-labelledby="heading-accordion"
        >
            <div class="accordion-body">
                <slot name="content"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'vf-accordion',
    props: {
        title: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped>
.accordion-button:not(.collapsed) {
    color: black;
    background-color: var(--vf-almost-white);
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--accordion-icon-open);
    transform: rotate(-180deg);
}
</style>
