export const getFragmentCodesBySupportedCharacterId = ( state ) => ( supportedCharacterId, elementIndex ) => {
    if ( state.fragmentCodes[ elementIndex ] && state.fragmentCodes[ elementIndex ][ supportedCharacterId ]) {
        return state.fragmentCodes[ elementIndex ][ supportedCharacterId ];
    }
    return [];
}

export const getFragmentCodeLengthById = ( state ) => ( fragmentCodeId, elementIndex, supportedCharacterId ) => {
    if (state.fragmentCodes[ elementIndex ] && state.fragmentCodes[ elementIndex ][ supportedCharacterId ]) {
        for (const fragmentCode of state.fragmentCodes[elementIndex][supportedCharacterId]) {
            if (fragmentCode.value === fragmentCodeId) {
                return fragmentCode;
            }
        }
    }
    return null;
}
