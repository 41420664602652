export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setGeneratorRules = ( state, value ) => {
    state.generatorRules = value;
}

export const clearGeneratorRules = ( state ) => {
    state.generatorRules = [];
}

export const clearStateStore = ( state ) => {
    state.generatorRules = [];
    state.isLoading = false;
}
