<template>
  <div
    class="row col-2 mb-3"
    v-show="enableButtonBack"
  >
    <a
      class="btn btn-secondary float-end btn-sm"
      :href="urlVF2"
    >
      <i class="fas fa-arrow-left p-1"></i> Back to {{ fromToVF2 }}
    </a>
  </div>
</template>

<script>
import { useVFButtonAuth } from '@/composables';

export default {
  name: 'vf-buttonAuth',
  setup () {
    const {
      urlVF2,
      fromToVF2,
      enableButtonBack
    } = useVFButtonAuth();

    return {
      urlVF2,
      fromToVF2,
      enableButtonBack
    }
  }
}
</script>
