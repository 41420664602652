const List = () => import(
    /* webpackChunkName: "CommodityIdentificationsList" */
    '@/modules/commodityIdentifications/views/List.vue'
);
const ManageData = () => import(
    /* webpackChunkName: "CommodityIdentificationManageData" */
    '@/modules/commodityIdentifications/views/ManageData.vue'
);
const View = () => import(
    /* webpackChunkName: "CommodityIdentificationView" */
    '@/modules/commodityIdentifications/views/View.vue'
);

export default [
    {
        path: '/commodity-identifications',
        name: "commodity_identifications",
        component: List,
        meta: {
            in_menu: true,
            public: false,
            icon: 'fa-solid fa-address-card',
            requiresAuth: true
        }
    },
    {
        path: '/commodity-identifications/create',
        name: "commodity_identification_create",
        component: ManageData,
        meta: {
            public: false,
            requiresAuth: true
        }
    },
    {
        path: '/commodity-identifications/view/:commodity_identification_id',
        name: "commodity_identification_view",
        component: View,
        meta: {
            public: false,
            requiresAuth: true
        }
    },
    {
        path: '/commodity-identifications/edit/:commodity_identification_id',
        name: "commodity_identification_edit",
        component: ManageData,
        meta: {
            public: false,
            requiresAuth: true
        }
    }
]

