const Create = () => import(
    /* webpackChunkName: "generatorRuleCreate" */
    '@/modules/generatorRules/views/Create.vue'
);

export default [
    {
        path: '/generator-rule',
        name: 'generator_rule',
        component: Create,
        meta: {
            in_menu: true,
            public: false,
            icon: 'fa-solid fa-sliders',
            requiresAuth: true
        }
    }
]

