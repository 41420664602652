import { Tooltip } from 'bootstrap';

const useTooltip = () => {
    const onInitTooltips = () => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].forEach( tooltipTriggerEl => new Tooltip( tooltipTriggerEl ));
    }

    const onHideTooltips = () => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].forEach( tooltipTriggerEl => {
            const tooltip = Tooltip.getInstance( tooltipTriggerEl );
            if ( tooltip ) {
                tooltip.hide();
            }
        });
    };

    return {
        onHideTooltips,
        onInitTooltips
    }
}

export default useTooltip;
