export const getConnectionsMessageError = ( error, defaultMessage ) => {
    if (error?.response?.data?.message) {
        return error.response.data.message;
    }
    return defaultMessage;
}

export const getMessageError = ( error, defaultMessage, alreadyExistMessage = '',  ) => {
    let message = defaultMessage;

    if (error?.response?.data?.message) {
        const backendMessage = error.response.data.message;

        message += backendMessage.includes('already been taken')
            ? `\n - ${ alreadyExistMessage }`
            : `\n - ${ backendMessage }`;

        return message;
    }
    return defaultMessage;
}
