<template>
    <label :class="classes"
           :for="inputId"
    >
        {{ text }}
        <span v-if="isRequired"
              class="text-primary"
        >*</span>
    </label>
</template>
<script>
export default {
    name: 'VF-label',
    props: {
        classes: {
            type: String,
            required: false,
            default: ''
        },
        inputId: {
            type: String,
            required: true
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        text: {
            type: String,
            required: true
        }
    }
}
</script>
